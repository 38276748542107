<template>
  <div>
    <Main class="general-background">
      <div class="generate-portfolio-main">
        <p>{{ $t("generate_portfolio.text") }}</p>
        <a-row align="middle" class="w-100 mt-4">
              <a-col :xs="20" :md="6">
                <a-select
                  v-model:value="selectedMandate"
                  class="w-100"
                  placeholder="Selecciona un mandato"
                  :options="mandatesOptions"
                  style="height: 45px"
                />
              </a-col>
          </a-row>

        <div>
          <div class="ranges-grid">
            <a-row gutter="32">
              <a-col :xs="24" :md="12" class="slider-column">
                <template v-for="slider in sliders.filter(s => s.column === 1)" :key="slider.label">
                  <base-slider
                    v-model="portfolioLimitStore.sliders[slider.label]"
                    :label="slider.label"
                    :steps="1"
                    :ranges="slider.ranges"
                    :max="slider.ranges.length - 1"
                    :default-value="slider.defaultValue" 
                    :interval="slider.interval"
                    class="the-slider"
                  ></base-slider>
                </template>
              </a-col>
              <a-col :xs="24" :md="12" class="slider-column">
                <template v-for="slider in sliders.filter(s => s.column === 2)" :key="slider.label">
                  <base-slider
                    v-model="portfolioLimitStore.sliders[slider.label]"
                    :label="slider.label"
                    :steps="1"
                    :ranges="slider.ranges"
                    :max="slider.ranges.length - 1"
                    :default-value="slider.defaultValue" 
                    :interval="slider.interval"
                    class="the-slider"
                  ></base-slider>
                </template>
              </a-col>
            </a-row> 
          </div>
        </div>

        <div v-if="checkboxes.length > 0">
          <a-row class="checkboxes-row" v-for="(checkbox, index) in checkboxes" :key="index">
            <a-col :span="24">
              <a-checkbox v-model="checkbox.value" @change="updateSliderValue(checkbox.label)">
                {{ checkbox.label }}
              </a-checkbox>
            </a-col>
          </a-row>
        </div>

        <a-row align="start" class="my-3">
          <a-col :span="24">
            <sdButton
              size="small"
              class="element-start"
              type="secondary"
              @click="generatePortfolio"
              :loading="portfolioLimitStore.loading"
            >
              {{ $t("generic.buttons.generate_portfolio") }}
            </sdButton>
          </a-col>
        </a-row>
      </div>
    </Main>
  </div>
</template>

<script setup>
import { Main } from "../styled";
import { ref,computed, onMounted, watch } from "vue";
import BaseSlider from "@/components/wiseral/BaseSlider";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";

const authStore = useAuthStore();
const router = useRouter();
const portfolioLimitStore = usePortfolioLimitStore();
const portfolioStore = usePortfolioStore();
const selectedMandate = ref(null);

const checkboxes = ref([]);

const generatePortfolio = async () => {
  const result = await portfolioLimitStore.generatePortfolio();
  
  if (result) {
    portfolioStore.portfolio_type = 1;
    portfolioStore.portfolio_id = null;
    portfolioStore.last_update_date = null;
    portfolioStore.selectedPortfolio = result;
    router.push({
      name: "portfolio-overview-ids",
      params: { ids: portfolioStore.selectedPortfolioIds.join(",") },
    });
  }
};

const sliders = ref([]); 
  
const mandatesOptions = computed(() => 
  portfolioStore.mandates.map(mandate => ({
   label: mandate.name, 
   value: mandate.id    
  }))
);

watch(
  () => selectedMandate.value,
  (newValue) => {
    if (newValue) {
      console.log(newValue);
      portfolioLimitStore.selected_mandate = newValue;

      // Aquí calculamos los sliders basados en el mandato seleccionado
      const selectedMandateData = portfolioStore.mandates.find(m => m.id === newValue);

      if (selectedMandateData && selectedMandateData.mandate_json) {
        // Accedemos a los datos dentro de mandate_json
        const mandateJson = JSON.parse(selectedMandateData.mandate_json);

        portfolioLimitStore.sliders = Object.keys(mandateJson.slider.intervals).reduce((acc, key) => {
          const slider = mandateJson.slider.intervals[key];
          acc[key] = slider.default - 1; // Valor por defecto ajustado a base 0
          return acc;
        }, {});  

        console.log(portfolioLimitStore.sliders);
        console.log('Valor inicial del slider:', portfolioLimitStore.sliders['RV/RF']);
        // Aquí mapeamos los valores de mandate_json para los sliders
        sliders.value = Object.keys(mandateJson.slider.intervals).map(key => {
          const slider = mandateJson.slider.intervals[key];

          // Usamos directamente el intervalo y el valor por defecto de la definición
          const interval = slider.intervals;  // Usamos directamente el valor de intervals
          const defaultValue = slider.default - 1; // Ajustamos el valor base 1 a base 0

          return {
            label: key,
            ranges: slider.values,
            column: slider.column,  // Determina en qué columna debe caer
            interval: interval,      // El intervalo directamente de la definición
            defaultValue: defaultValue // El valor por defecto ajustado
          };
        });

        if (mandateJson.slider.checkboxes) {
          checkboxes.value = mandateJson.slider.checkboxes.map(checkbox => ({
            label: checkbox.literal,
            value: checkbox.default // Valor por defecto
          }));
        }

        console.log('Checkboxes:', checkboxes.value);
        checkboxes.value.forEach(checkbox => {
            portfolioLimitStore.sliders[checkbox.label] = checkbox.value;
        });
      }

    }
  }
);

const updateSliderValue = (label) => {
  portfolioLimitStore.sliders[label] = !portfolioLimitStore.sliders[label]; // Actualiza el store manualmente
  console.log(`Checkbox "${label}" actualizado a:`, portfolioLimitStore.sliders[label]);
};

onMounted(async () => {
  portfolioLimitStore.invest_amount =
    authStore.config["default_investment_amount"];
    portfolioStore.original_portfolio_name = null;
    await portfolioStore.getUserPortfolios();
    await portfolioStore.fetchMandates();
});
</script>

<style scoped lang="scss">
.general-background {
  background-color: inherit;
}

.checkboxes-row {
  margin-bottom: 15px;
}

.slider-column {
  padding: 10px; /* Espaciado interno entre las columnas */
}

.the-slider {
  margin-bottom: 25px; /* Espaciado entre sliders */
  :deep(.slider-label) {
    margin-bottom: 2px; /* Espaciado entre label y el slider */
    display: inline-block;     /* Asegúrate de que esté en una línea separada */
    font-size: 14px;    /* Ajusta el tamaño del label si es necesario */
  }

  :deep(.slider-base) {
    margin-top: 3px;  /* Reduce espacio adicional dentro del slider */
  }
}

.ranges-grid {
  display: flex; /* Asegura que las columnas usen flexbox */
  flex-wrap: wrap; /* Permite que las columnas se ajusten en pantallas pequeñas */
  justify-content: space-between; /* Añade espacio entre columnas */
  gap: 16px; /* Espaciado uniforme entre elementos */
  row-gap: 30px; /* Espaciado vertical entre filas */
  margin: 0 auto;
  & > * {
    width: 100%;
    padding: 15px 0;
  }
}

@media only screen and (min-width: 1149px) {
  .ranges-grid {
    margin: 40px auto 40px auto;
    max-width: 80vw;

    & > * {
      width: 47%;
      padding: 20px;
    }
  }
}

@media only screen and (min-width: 2500px) {
  .ranges-grid {
    display: flex;
    max-width: 60vw;

    & > * {
      width: 40%;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ranges-grid {
    & > * {
      min-width: 100%;
      padding: 20px;
    }
  }
  .the-slider {
    display: block;
    margin: 10px 0;
    min-width: 100%;

    :deep(span) {
      display: block;
      min-width: max-content;
    }

    :deep(.slider-base) {
      margin-top: 20px;
      min-width: 70vw;
    }
  }
}

@media only screen and (max-width: 380px) {
  .ranges-grid {
    & > * {
      padding: 20px 10px;
    }
  }
}
</style>
